<template>
  <v-card elevation="2" :loading="isLoading">
    <v-system-bar flat color="blue lighten-4">
      <span class="text-subtitle-1">
        Блок/бан вследствие атомарных действий
      </span>
      <v-spacer></v-spacer>
      <v-icon
        v-if="showContent"
        @click="showContent = !showContent"
        style="cursor: pointer"
        >mdi-chevron-down</v-icon
      >
      <v-icon v-else @click="showContent = !showContent" style="cursor: pointer"
        >mdi-chevron-up</v-icon
      >
    </v-system-bar>
    <div v-if="showContent" class="d-flex">
      <div style="max-width: 145px">
        <v-tabs
          vertical
          center-active
          v-model="selectedTab"
          color="grey darken-4"
        >
          <v-tabs-slider color="blue lighten-4"></v-tabs-slider>
          <v-tab v-for="item in availableTargets" :key="item">
            {{ item }}
          </v-tab>
        </v-tabs>
      </div>
      <div class="pa-3" style="width: calc(100% - 145px)">
        <div class="d-flex flex-wrap pl-4">
          <v-list>
            <v-list-item>
              <v-list-item-action>
                <v-switch v-model="allStages" color="blue lighten-3"></v-switch>
              </v-list-item-action>
              <v-list-item-title>Все стейджи</v-list-item-title>
            </v-list-item>
          </v-list>
          <v-checkbox
            v-for="(stage, i) in availableStages"
            v-model="selectedStages"
            :key="i"
            :label="stage.toString()"
            :value="stage"
            color="blue lighten-3"
            class="mr-6"
          ></v-checkbox>
        </div>

        <div class="px-6">
          <v-row>
            <v-col
              ><v-menu
                ref="menu"
                v-model="rangePickerModal"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                :return-value.sync="statisticRange"
                offset-y
                min-width="100px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    name="date-search"
                    label="Период"
                    v-model="statisticRange"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  no-title
                  range
                  scrollable
                  v-model="statisticRange"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="grey" @click="rangePickerModal = false">
                    {{ $t("cancel") }}
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="
                      $refs.menu.save(statisticRange);
                      setRange();
                    "
                  >
                    {{ $t("ok") }}
                  </v-btn>
                </v-date-picker>
              </v-menu></v-col
            >
            <v-col>
              <v-autocomplete
                :items="availableAccountTypeLabels"
                item-text="title"
                item-value="id"
                menu-props="closeOnContentClick"
                deletable-chips
                multiple
                small-chips
                hide-no-data
                hide-selected
                name="type"
                label="Тип аккаунта"
                v-model="selectedType"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </div>
        <bar-chart :chartData="chartDataset" :options="options"></bar-chart>
      </div>
    </div>
    <div v-if="showContent">
      <v-tabs
        center-active
        show-arrows
        v-model="selectedCodeTab"
        color="grey darken-4"
      >
        <v-tabs-slider color="blue lighten-4"></v-tabs-slider>
        <v-tab v-for="(item, index) in itemsByCode" :key="index">
          {{ index }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="selectedCodeTab">
        <v-tab-item v-for="(group, index) in groupedItemsByCode" :key="index">
          <v-simple-table class="ma-3">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Последнее действие аккаунта, получивший блок или бан
                  </th>
                  <th class="text-left">Колличество аккаунтов</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in group" :key="index">
                  <td>{{ item.taskCommand }}</td>
                  <td>{{ item.qty }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </v-card>
</template>

<script>
import { FETCH_TASK_STAT } from "@/store/modules/main/actions";

import BarChart from "@/views/base/charts/Bar";
import moment from "moment";

import _ from "lodash";
import { mapGetters } from "vuex";
import { FETCH_ACCOUNT_TYPES } from "@/store/actions/donor";

export default {
  name: "AtomicActionStat",
  components: { BarChart },
  data: function () {
    return {
      showContent: true,
      atomicActionStatistics: [],
      selectedTab: 0,
      selectedCodeTab: 0,
      isLoading: true,
      menu: false,
      allStages: null,
      selectedStages: [3, 4],
      selectedType: [1],
      rangePickerModal: false,
      statisticRange: null,
      reportDateFrom: null,
      reportDateTo: null,
      availableAccountTypeLabels: [],
    };
  },
  computed: {
    ...mapGetters("account/donor", ["availableStages"]),
    ...mapGetters("service", ["scopes"]),
    availableCodes: function () {
      return [
        727,
        730,
        723,
        722,
        720,
        719,
        717,
        715,
        714,
        713,
        711,
        710,
        708,
        707,
        706,
        705,
        704,
      ];
    },
    availableTargets: function () {
      return this.scopes;
    },
    selectedTarget: function () {
      return this.availableTargets[this.selectedTab];
    },
    filteredItems: function () {
      let vm = this;
      return this.atomicActionStatistics.filter((item) => {
        return (
          vm.selectedStages.indexOf(item.stage) > -1 &&
          vm.selectedType.indexOf(item.type) > -1
        );
      });
    },
    groupedItems: function () {
      return _.groupBy(this.filteredItems, "target");
    },
    itemsByTarget: function () {
      return _.get(this.groupedItems, this.selectedTarget, []);
    },
    itemsByCode: function () {
      return this.itemsByTarget.length
        ? _.groupBy(this.itemsByTarget, "code")
        : {};
    },
    groupedItemsByCode: function () {
      let result = {};
      let vm = this;
      _.forIn(vm.itemsByCode, function (group, code) {
        let groupedByAction = _.groupBy(group, "taskCommand");
        let groupResult = [];
        _.forIn(groupedByAction, function (taskGroup, taskCommand) {
          groupResult.push({
            taskCommand,
            qty: taskGroup
              .map((item) => {
                return parseInt(item.qty);
              })
              .reduce(
                (previousValue, currentValue) => previousValue + currentValue,
                0
              ),
          });
        });
        result[code] = groupResult;
      });

      return result;
    },
    chartDataset: function () {
      const vm = this;
      const availableColors = [
        "#A5D6A7",
        "#C5CAE9",
        "#FFCDD2",
        "#90CAF9",
        "#FFECB3",
        "#FFCCBC",
        "#BBDEFB",
        "#DCEDC8",
      ];
      let datasets = [];
      let groupIndex = 0;

      _.forIn(vm.itemsByCode, function (group, code) {
        let data = [
          group
            .map((item) => {
              return parseInt(item.qty);
            })
            .reduce(
              (previousValue, currentValue) => previousValue + currentValue,
              0
            ),
        ];

        datasets.push({
          label: code,
          fill: false,
          data: data,
          borderColor: availableColors[groupIndex],
          backgroundColor: availableColors[groupIndex],
        });
        groupIndex++;
      });

      return {
        datasets,
      };
    },
    options: function () {
      return {
        responsive: true,
        indexAxis: "x",
        plugins: {
          title: {
            text: "Статистика атомарных действий",
            display: true,
          },
        },
        scales: {
          y: {
            min: 0,
          },
          xAxes: {
            type: "time",
            time: {
              // Luxon format string
              tooltipFormat: "DD T",
            },
            title: {
              display: true,
              text: "Date",
            },
          },
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
              title: {
                display: true,
                text: "value",
              },
            },
          ],
        },
        legend: {
          position: "bottom",
          labels: {
            padding: 5,
            usePointStyle: true,
            font: {
              size: 10,
              lineHeight: 1,
            },
          },
          onHover: function (e) {
            e.target.style.cursor = "pointer";
          },
        },
        animations: {
          tension: {
            duration: 0,
            easing: "linear",
            from: 1,
            to: 0,
            loop: false,
          },
        },
      };
    },
    formattedRange: function () {
      let { reportDateFrom, reportDateTo } = this;
      return [reportDateFrom, reportDateTo].join(" - ");
    },
  },
  watch: {
    allStages: function (newValue) {
      if (newValue) {
        this.selectedStages = [0, 2, 3, 4];
      } else {
        this.selectedStages = [];
      }
    },
  },
  methods: {
    setRange: function () {
      this.statisticRange.sort(function (a, b) {
        a = moment(a);
        b = moment(b);
        return a > b ? 1 : a < b ? -1 : 0;
        // return a.localeCompare(b);         // <-- alternative
      });
      if (this.statisticRange.length === 2) {
        this.reportDateFrom = this.statisticRange[0];
        this.reportDateTo = this.statisticRange[1];
        this.fetchItems();
      } else if (this.statisticRange.length === 1) {
        this.reportDateFrom = this.statisticRange[0];
        this.reportDateTo = this.statisticRange[0];
        this.fetchItems();
      } else {
        this.tasksStatistics = [];
      }
    },
    fetchItems: function () {
      const vm = this;
      this.isLoading = true;
      let { reportDateFrom, reportDateTo } = this;
      this.$store
        .dispatch(FETCH_TASK_STAT, { reportDateFrom, reportDateTo })
        .then((payload) => {
          this.isLoading = false;
          this.atomicActionStatistics = payload.batch.filter(function (item) {
            return vm.availableCodes.indexOf(item.code) > -1;
          });
        });
    },
    fetchAccountTypes: function () {
      this.isLoading = true;
      this.$store
        .dispatch("account/donor/" + FETCH_ACCOUNT_TYPES, {
          limit: 50,
          offset: 0,
        })
        .then(
          (payload) => {
            this.isLoading = false;
            this.availableAccountTypeLabels = payload.data;
          },
          () => {}
        )
        .catch(() => {});
    },
  },
  mounted() {
    this.reportDateFrom = moment().add(-1, "days").format("YYYY-MM-DD");
    this.reportDateTo = moment().format("YYYY-MM-DD");
    this.statisticRange = [this.reportDateFrom, this.reportDateTo];
    this.fetchItems();
    this.fetchAccountTypes();
  },
};
</script>

<style>
.v-tabs--vertical > .v-tabs-bar .v-tab {
  height: 30px !important;
}
</style>
