var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"2","loading":_vm.isLoading}},[_c('v-system-bar',{attrs:{"flat":"","color":"blue lighten-4"}},[_c('span',{staticClass:"text-subtitle-1"},[_vm._v("Статистика выдачи задач в рамках эмуляции")]),_c('v-spacer'),(_vm.showContent)?_c('v-icon',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.showContent = !_vm.showContent}}},[_vm._v("mdi-chevron-down")]):_c('v-icon',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.showContent = !_vm.showContent}}},[_vm._v("mdi-chevron-up")])],1),(_vm.showContent)?_c('div',{staticClass:"d-flex"},[_c('div',{staticStyle:{"max-width":"145px"}},[_c('v-tabs',{attrs:{"vertical":"","center-active":"","color":"grey darken-4"},on:{"change":_vm.fetchItems},model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},[_c('v-tabs-slider',{attrs:{"color":"blue lighten-4"}}),_vm._l((_vm.availableTargets),function(target,i){return _c('v-tab',{key:i},[_vm._v(" "+_vm._s(target)+" ")])})],2)],1),_c('div',{staticClass:"flex-grow-1 ml-15"},[_c('v-select',{staticStyle:{"max-width":"180px"},attrs:{"item-text":"label","item-value":"value","items":_vm.periods,"label":"Период"},model:{value:(_vm.selectedPeriod),callback:function ($$v) {_vm.selectedPeriod=$$v},expression:"selectedPeriod"}}),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.emulationSummaryList,"hide-default-header":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"x-small":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.showDetails(item)}}},[_c('v-icon',[_vm._v("mdi-eye-outline")])],1)]}},{key:"item.count",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold black--text"},[_vm._v(_vm._s(item.total))]),_vm._v("/ "),_c('span',{staticClass:"font-weight-bold green--text"},[_vm._v(_vm._s(item.numberSuccessful))]),_vm._v("/ "),_c('span',{staticClass:"font-weight-bold red--text"},[_vm._v(_vm._s(item.numberFailed))])]}},{key:"item.requestType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.requestTypeAlias[item.requestType])+" ")]}}],null,false,1236297334)}),_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.showDetailsStatus),callback:function ($$v) {_vm.showDetailsStatus=$$v},expression:"showDetailsStatus"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline primary lighten-3 white--text justify-space-between"},[_vm._v(" Детали эмуляции "),_c('v-btn',{attrs:{"icon":"","color":"white","title":_vm.$t('close')},on:{"click":function($event){_vm.showDetailsStatus = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.detailsHeaders,"items":_vm.emulationDetailsList},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.type)+" ")]}}],null,false,1914579663)})],1)],1)],1)],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }