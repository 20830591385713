<template>
  <v-card elevation="2" :loading="isLoading">
    <v-system-bar flat color="blue lighten-4">
      <span class="text-subtitle-1">Статистика контента</span>
      <v-spacer></v-spacer>
      <v-icon
        v-if="showContent"
        @click="showContent = !showContent"
        style="cursor: pointer"
        >mdi-chevron-down</v-icon
      >
      <v-icon v-else @click="showContent = !showContent" style="cursor: pointer"
        >mdi-chevron-up</v-icon
      >
    </v-system-bar>
    <div v-if="showContent" class="d-flex px-4">
      <v-row class="mt-3">
        <v-col sm="12" md="5" lg="5">
          <v-autocomplete
            :items="availableTypes"
            item-text="label"
            item-value="key"
            label="Тип"
            v-model="filters.type"
            menu-props="closeOnContentClick"
            deletable-chips
            multiple
            small-chips
            hide-no-data
            hide-selected
            name="type"
          >
          </v-autocomplete>
        </v-col>
        <v-col sm="12" md="5" lg="5">
          <v-autocomplete
            :items="availableInitiators"
            item-text="label"
            item-value="key"
            label="Источник"
            v-model="filters.initiatorType"
            menu-props="closeOnContentClick"
            deletable-chips
            multiple
            small-chips
            hide-no-data
            hide-selected
            name="initiatorType"
          >
          </v-autocomplete>
        </v-col>
        <tag-search
          v-model="filters.tag"
          label="Проф.тип"
          :removeble="false"
          :allowEmpty="true"
        ></tag-search>
        <language-search
          v-model="filters.language"
          label="Язык"
          :removeble="false"
          :allowEmpty="true"
        ></language-search>
        <gender-search
          v-model="filters.gender"
          label="Пол"
          :removeble="false"
          :allowEmpty="true"
        ></gender-search>
        <age-group-search
          v-model="filters.ageGroup"
          label="Возраст"
          :removeble="false"
          :allowEmpty="true"
        ></age-group-search>
        <v-col>
          <v-btn
            x-small
            outlined
            color="red"
            dark
            @click="resetFilters"
            class="mt-6"
          >
            {{ $t("resetFilters") }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <v-data-table
      v-if="showContent"
      :headers="headers"
      :items="filteredData"
      :search="search"
      class="elevation-0"
    >
      <template v-slot:item.gender="{ item }">
        <v-icon small>
          mdi-gender-{{ getSymbolByGender({ gender: item.gender }) }}
        </v-icon>
      </template>
      <template v-slot:item.type="{ item }">
        {{ $t(`dashboard.content.type.${item.type}`) }}
      </template>
      <template v-slot:item.initiatorType="{ item }">
        {{ $t(`dashboard.initiator.${item.initiatorType}`) }}
      </template>
      <template v-slot:item.language="{ item }">
        <flag :iso="getAbrByLanguage({ language: item.language })" />
      </template>
      <template v-slot:item.tag="{ item }">
        <v-chip v-if="item.tag" small>{{ item.tag.name }}</v-chip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { FETCH_CONTENT_STAT } from "@/store/modules/main/actions";
import GenderSearch from "../../search-parts/GenderSearch";
import { mapGetters } from "vuex";
import LanguageSearch from "../../search-parts/LanguageSearch";
import TagSearch from "../../search-parts/TagSearch";
import AgeGroupSearch from "../../search-parts/AgeGroupSearch";

export default {
  name: "ContentStat",
  components: {
    LanguageSearch,
    GenderSearch,
    TagSearch,
    AgeGroupSearch,
  },
  data: function () {
    return {
      showContent: true,
      contentStatistics: [],
      isLoading: true,
      search: null,
      filters: {
        type: [],
        gender: [],
        language: [],
        tag: [],
        initiatorType: [],
        ageGroup: [],
      },
    };
  },
  computed: {
    formattedRange: function () {
      let { reportDateFrom, reportDateTo } = this;
      return [reportDateFrom, reportDateTo].join(" - ");
    },
    headers: function () {
      return [
        {
          text: "Тип",
          align: "start",
          sortable: false,
          value: "type",
        },
        {
          text: "Пол",
          align: "start",
          value: "gender",
        },
        {
          text: "Язык",
          align: "start",
          value: "language",
        },
        {
          text: "Профтип",
          align: "start",
          sortable: false,
          value: "tag",
        },
        {
          text: "Возраст",
          align: "start",
          sortable: false,
          value: "ageGroup",
        },
        {
          text: "Источник",
          align: "start",
          sortable: false,
          value: "initiatorType",
        },
        {
          text: "Кол-во контента",
          align: "start",
          value: "qty",
        },
      ];
    },
    filteredData: function () {
      const vm = this;
      return this.contentStatistics.filter(function (item) {
        return (
          (vm.filters.type.length === 0 ||
            vm.filters.type.indexOf(item.type) > -1) &&
          (vm.filters.gender.length === 0 ||
            (vm.filters.gender.indexOf("notSet") > -1 && !item.gender) ||
            vm.filters.gender.indexOf(item.gender) > -1) &&
          (vm.filters.language.length === 0 ||
            (vm.filters.language.indexOf("notSet") > -1 && !item.language) ||
            vm.filters.language.indexOf(item.language) > -1) &&
          (vm.filters.tag.length === 0 ||
            (vm.filters.tag.indexOf("notSet") > -1 && !item.tag) ||
            (item.tag && vm.filters.tag.indexOf(item.tag.id) > -1)) &&
          (vm.filters.initiatorType.length === 0 ||
            (item.initiatorType &&
              vm.filters.initiatorType.indexOf(item.initiatorType) > -1)) &&
          (vm.filters.ageGroup.length === 0 ||
            (vm.filters.ageGroup.indexOf("notSet") > -1 && !item.ageGroup) ||
            vm.filters.ageGroup.indexOf(item.ageGroup) > -1)
        );
      });
    },
    availableTypes: function () {
      return [
        {
          key: "write_text",
          label: this.$t("dashboard.content.type.write_text"),
        },
        {
          key: "write_image",
          label: this.$t("dashboard.content.type.write_image"),
        },
        {
          key: "write_text_image",
          label: this.$t("dashboard.content.type.write_text_image"),
        },
        {
          key: "write_text_link",
          label: this.$t("dashboard.content.type.write_text_link"),
        },
        {
          key: "write_text_marker",
          label: this.$t("dashboard.content.type.write_text_marker"),
        },
        {
          key: "write_text_image_marker",
          label: this.$t("dashboard.content.type.write_text_image_marker"),
        },
      ];
    },
    availableInitiators: function () {
      return [
        {
          key: "api",
          label: this.$t("dashboard.initiator.api"),
        },
        {
          key: "user",
          label: this.$t("dashboard.initiator.user"),
        },
        {
          key: "import",
          label: this.$t("dashboard.initiator.import"),
        },
      ];
    },
    ...mapGetters("account/donor", ["getSymbolByGender", "getAbrByLanguage"]),
  },
  methods: {
    fetchItems: function () {
      this.isLoading = true;
      this.$store.dispatch(FETCH_CONTENT_STAT).then((payload) => {
        this.isLoading = false;
        this.contentStatistics = payload.batch;
      });
    },
    resetFilters: function () {
      this.filters = {
        type: [],
        gender: [],
        language: [],
        tag: [],
        initiatorType: [],
        ageGroup: [],
      };
    },
  },
  mounted() {
    this.fetchItems();
  },
};
</script>

<style lang="scss" scoped>
@import "/src/theme/scss/resetGridPadding";
</style>
