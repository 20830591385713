<template>
  <v-card elevation="2" :loading="isLoading">
    <v-system-bar flat color="blue lighten-4">
      <span class="text-subtitle-1">Статистика синхронизации</span>
      <v-spacer></v-spacer>
      <v-icon
        v-if="showContent"
        @click="showContent = !showContent"
        style="cursor: pointer"
        >mdi-chevron-down</v-icon
      >
      <v-icon v-else @click="showContent = !showContent" style="cursor: pointer"
        >mdi-chevron-up</v-icon
      >
    </v-system-bar>
    <div v-if="showContent" class="d-flex">
      <div style="max-width: 145px">
        <v-tabs
          vertical
          center-active
          v-model="selectedTab"
          color="grey darken-4"
        >
          <v-tabs-slider color="blue lighten-4"></v-tabs-slider>
          <v-tab v-for="item in availableTargets" :key="item">
            {{ item }}
          </v-tab>
        </v-tabs>
      </div>
      <div class="pa-3 ml-15" style="width: calc(100% - 145px)">
        <v-data-table
          disable-pagination
          disable-filtering
          hide-default-footer
          :headers="scheduleHeaders"
          :items="scheduleByTarget"
          :sort-by="['lastSyncStatus']"
          :sort-desc="[false]"
          class="elevation-0"
        >
          <template v-slot:item.id="{ item }">
            <v-btn x-small icon color="primary" @click="showTasks(item.id)">
              <v-icon>mdi-eye-outline</v-icon>
            </v-btn>
          </template>
          <template v-slot:item.regularDateMask="{ item }">
            {{ getFormattedSchedule(item.regularDateMask) }}
          </template>
          <template v-slot:item.action="{ item }">
            {{ $t(`dashboard.sync.action.${item.action}`) }}
            <div v-if="item.parameters && item.parameters.privacy_settings">
              (С настройками приватности)
            </div>
          </template>
          <template v-slot:item.lastSyncStatus="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on" class="ml-2">
                  <v-icon v-if="item.lastSyncStatus === 'error'" color="red"
                    >mdi-alert-circle-outline</v-icon
                  >
                  <v-icon
                    v-else-if="item.lastSyncStatus === 'warning'"
                    color="orange"
                    >mdi-alert-outline</v-icon
                  >
                  <v-icon
                    v-else-if="
                      ['pending', 'sent', 'new'].indexOf(item.lastSyncStatus) >
                      -1
                    "
                    color="blue"
                    >mdi-loading</v-icon
                  >
                  <v-icon v-else color="green">mdi-clock-check-outline</v-icon>
                  <span
                    v-if="
                      ['pending', 'sent', 'new'].indexOf(item.lastSyncStatus) >
                      -1
                    "
                    >Обработка</span
                  >
                  <span v-else
                    >{{ item.lastSyncUpdatedItems }}/{{
                      item.lastSyncItems
                    }}</span
                  >
                </span>
              </template>
              <div>Обновленно сущностей: {{ item.lastSyncUpdatedItems }}</div>
              <div>Общее количество сущностей: {{ item.lastSyncItems }}</div>
            </v-tooltip>
          </template>
        </v-data-table>
        <v-dialog
          v-model="showSchedule"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-card-title
              class="headline primary lighten-3 white--text justify-space-between"
            >
              Список задач расписания
              <v-menu
                ref="menu"
                v-model="rangePickerModal"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                :return-value.sync="statisticRange"
                offset-y
                min-width="100px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    solo
                    hide-details
                    name="date-search"
                    label="Период"
                    v-model="statisticRange"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    style="max-width: 300px"
                  ></v-text-field>
                </template>
                <v-date-picker
                  no-title
                  range
                  scrollable
                  v-model="statisticRange"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="grey" @click="rangePickerModal = false">
                    {{ $t("cancel") }}
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="
                      $refs.menu.save(statisticRange);
                      setRange();
                    "
                  >
                    {{ $t("ok") }}
                  </v-btn>
                </v-date-picker>
              </v-menu>
              <v-autocomplete
                solo
                :items="availableStatuses"
                item-text="label"
                item-value="key"
                label="Статус"
                v-model="taskStatus"
                menu-props="closeOnContentClick"
                deletable-chips
                multiple
                small-chips
                hide-no-data
                hide-selected
                name="status"
                style="max-width: 300px"
                hide-details
              >
              </v-autocomplete>
              <v-btn
                icon
                color="white"
                :title="$t('close')"
                @click="closeSchedule()"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>

            <v-card-text>
              <v-data-table
                :headers="headers"
                :items="filteredData"
                :search="search"
                class="elevation-0"
              >
                <template v-slot:item.action="{ item }">
                  {{ $t(`dashboard.sync.action.${item.action}`) }}
                </template>
                <template v-slot:item.status="{ item }">
                  {{ $t(`dashboard.sync.status.${item.status}`) }}
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </v-card>
</template>

<script>
import _ from "lodash";
import cronstrue from "cronstrue/i18n";

import {
  FETCH_SYNCHRONIZATION_TASK_STAT,
  FETCH_SYNCHRONIZATION_SCHEDULE,
} from "@/store/modules/main/actions";

import moment from "moment";
import { mapGetters } from "vuex";

export default {
  name: "SyncTasksStat",
  data: function () {
    return {
      showContent: true,
      tasksStatistics: [],
      schedule: [],
      isLoading: true,
      selectedTab: 0,
      search: null,
      menu: false,
      rangePickerModal: false,
      showSchedule: false,
      statisticRange: [],
      selectedSchedule: null,
      reportDateFrom: null,
      reportDateTo: null,
      taskStatus: [],
    };
  },
  computed: {
    ...mapGetters("service", ["scopes"]),
    headers: function () {
      return [
        {
          text: "Task ID",
          align: "start",
          value: "taskId",
        },
        {
          text: "Действие",
          align: "start",
          value: "action",
        },
        {
          text: "Исходное кол-во записей",
          align: "start",
          value: "items",
        },
        {
          text: "Кол-во записей обновленно/созданно",
          align: "start",
          value: "itemsUpdated",
        },
        {
          text: "Статус",
          align: "start",
          value: "status",
        },
        {
          text: "Дата вычитки",
          align: "start",
          value: "dispatchAt",
        },
      ];
    },
    scheduleHeaders: function () {
      return [
        {
          text: "Действие",
          align: "start",
          value: "action",
        },
        {
          text: "График",
          align: "start",
          value: "regularDateMask",
        },
        {
          text: "Дата последнего запуска",
          align: "start",
          value: "lastSyncLaunchDate",
          width: 200,
        },
        {
          text: "Статус последнего запуска",
          align: "start",
          value: "lastSyncStatus",
          width: 230,
        },
        {
          text: "Следующий запуск",
          align: "start",
          value: "nextLaunchDate",
          width: 200,
        },
        {
          text: "",
          align: "start",
          value: "id",
        },
      ];
    },
    availableStatuses: function () {
      return [
        { label: "Созданно", key: "new" },
        { label: "В ожидании", key: "pending" },
        { label: "Отправленно", key: "sent" },
        { label: "В обработке", key: "processing" },
        { label: "Завершено", key: "completed" },
        { label: "Ошибка", key: "failed" },
      ];
    },
    availableTargets: function () {
      return this.scopes;
    },
    selectedTarget: function () {
      return this.availableTargets[this.selectedTab];
    },
    groupedItems: function () {
      return _.groupBy(this.tasksStatistics, function (item) {
        return _.get(item, "parameters.domain");
      });
    },
    itemsByTarget: function () {
      return _.get(this.groupedItems, this.selectedTarget, []);
    },
    groupedSchedule: function () {
      return _.groupBy(this.schedule, function (item) {
        return _.get(item, "parameters.domain");
      });
    },
    scheduleByTarget: function () {
      return _.get(this.groupedSchedule, this.selectedTarget, []);
    },
    filteredData: function () {
      const vm = this;
      return this.tasksStatistics.filter(function (item) {
        return (
          vm.taskStatus.length === 0 || vm.taskStatus.indexOf(item.status) > -1
        );
      });
    },
  },
  methods: {
    setRange: function () {
      this.statisticRange.sort(function (a, b) {
        a = moment(a);
        b = moment(b);
        return a > b ? 1 : a < b ? -1 : 0;
        // return a.localeCompare(b);         // <-- alternative
      });
      if (this.statisticRange.length === 2) {
        this.reportDateFrom = this.statisticRange[0];
        this.reportDateTo = this.statisticRange[1];
        this.fetchItems();
      } else if (this.statisticRange.length === 1) {
        this.reportDateFrom = this.statisticRange[0];
        this.reportDateTo = this.statisticRange[0];
        this.fetchItems();
      } else {
        this.tasksStatistics = [];
      }
    },
    fetchItems: function () {
      this.isLoading = true;
      this.isLoading = true;
      let {
        reportDateFrom,
        reportDateTo,
        selectedSchedule,
        selectedTarget,
      } = this;
      this.$store
        .dispatch(FETCH_SYNCHRONIZATION_TASK_STAT, {
          reportDateFrom,
          reportDateTo,
          scheduleId: selectedSchedule,
          target: selectedTarget,
        })
        .then((payload) => {
          this.isLoading = false;
          this.tasksStatistics = payload.batch;
        });
    },
    fetchSchedule: function () {
      this.isLoading = true;
      this.$store.dispatch(FETCH_SYNCHRONIZATION_SCHEDULE).then((payload) => {
        this.isLoading = false;
        this.schedule = payload.batch;
      });
    },
    getFormattedSchedule: function (mask) {
      return cronstrue.toString(mask, { locale: "ru" });
    },
    showTasks: function (schedule) {
      this.selectedSchedule = schedule;
      this.showSchedule = true;
      this.reportDateFrom = moment().add(-1, "days").format("YYYY-MM-DD");
      this.reportDateTo = moment().format("YYYY-MM-DD");
      this.statisticRange = [this.reportDateFrom, this.reportDateTo];
      this.fetchItems();
    },
    closeSchedule: function () {
      this.selectedSchedule = null;
      this.showSchedule = false;
      this.statisticRange = [];
      this.tasksStatistics = [];
    },
  },
  mounted() {
    this.reportDateFrom = moment().add(-1, "days").format("YYYY-MM-DD");
    this.reportDateTo = moment().format("YYYY-MM-DD");
    this.fetchSchedule();
  },
};
</script>

<style>
.v-tabs--vertical > .v-tabs-bar .v-tab {
  height: 30px !important;
}
</style>
