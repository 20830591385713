<template>
  <v-card elevation="2" :loading="isLoading">
    <v-system-bar flat color="blue lighten-4">
      <span class="text-subtitle-1"> Статистика прокси </span>
      <v-spacer></v-spacer>
      <v-icon
        v-if="showContent"
        @click="showContent = !showContent"
        style="cursor: pointer"
        >mdi-chevron-down</v-icon
      >
      <v-icon v-else @click="showContent = !showContent" style="cursor: pointer"
        >mdi-chevron-up</v-icon
      >
    </v-system-bar>
    <div v-if="showContent" class="d-flex px-4">
      <v-spacer></v-spacer>
      <v-row>
        <v-col>
          <v-menu
            ref="menu"
            v-model="rangePickerModal"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            :return-value.sync="statisticRange"
            offset-y
            min-width="100px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                name="date-search"
                label="Период"
                v-model="statisticRange"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker no-title range scrollable v-model="statisticRange">
              <v-spacer></v-spacer>
              <v-btn text color="grey" @click="rangePickerModal = false">
                {{ $t("cancel") }}
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  $refs.menu.save(statisticRange);
                  setRange();
                "
              >
                {{ $t("ok") }}
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Поиск"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
    </div>
    <v-data-table
      v-if="showContent"
      :headers="headers"
      :items="filteredProxyStatistics"
      class="elevation-0"
      :sort-by="['actionsWithError']"
      :sort-desc="[true]"
    >
      <template v-slot:item.stat="{ item }">
        <div class="d-flex flex-nowrap">
          <div
            v-for="(statisticItem, i) in item.statistics"
            :key="i"
            class="px-3"
          >
            <router-link
              :to="{
                name: 'AccountList',
                query: {
                  proxy: item.address,
                  target: [statisticItem.name],
                  selectedFilters: ['proxy', 'target'],
                },
              }"
              class="text-decoration-none grey--text text--darken-4"
            >
              <v-icon
                v-text="
                  'mdi-' +
                  (statisticItem.name === 'vkontakte'
                    ? 'alpha-b-box'
                    : statisticItem.name)
                "
              ></v-icon>
              <span v-text="statisticItem.value" class="pl-1"></span>
            </router-link>
          </div>
        </div>
      </template>
      <template v-slot:item.package="{ item }">
        <router-link
          v-if="item.proxyPackage"
          :to="{
            name: 'ProxyPackageList',
            query: { id: item.proxyPackage.id },
          }"
          class="pr-1 d-inline-block text-truncate"
          style="max-width: 100%"
        >
          {{ item.proxyPackage.title }}
        </router-link>
        <span v-if="!item.proxyPackage" class="d-lg-none grey--text caption">
          Proxy package not set
        </span>
      </template>
      <template v-slot:item.actionsWithError="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              {{ item.actionsWithError }} / {{ item.totalActions }}
            </span>
          </template>
          <div>Действий с ошибкой: {{ item.actionsWithError }}</div>
          <div>Общее количество действий: {{ item.totalActions }}</div>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { FETCH_PROXY_STAT } from "@/store/modules/main/actions";
import moment from "moment";

export default {
  name: "ProxyStat",
  data: function () {
    return {
      showContent: true,
      proxyStatistics: [],
      isLoading: true,
      menu: false,
      rangePickerModal: false,
      statisticRange: null,
      reportDateFrom: null,
      reportDateTo: null,
      search: null,
    };
  },
  computed: {
    formattedRange: function () {
      let { reportDateFrom, reportDateTo } = this;
      return [reportDateFrom, reportDateTo].join(" - ");
    },
    headers: function () {
      return [
        {
          text: "Адрес прокси",
          align: "start",
          sortable: false,
          value: "address",
        },
        {
          text: "",
          align: "start",
          sortable: false,
          value: "stat",
        },
        {
          text: "Пакет",
          align: "start",
          sortable: false,
          value: "package",
        },
        {
          text: "Кол-во ошибок",
          align: "start",
          value: "actionsWithError",
        },
      ];
    },
    filteredProxyStatistics: function () {
      let vm = this;
      return this.proxyStatistics.filter(function (item) {
        return (
          !vm.search ||
          item.address.includes(vm.search) ||
          (item.proxyPackage && item.proxyPackage.title.includes(vm.search))
        );
      });
    },
  },
  methods: {
    setRange: function () {
      this.statisticRange.sort(function (a, b) {
        a = moment(a);
        b = moment(b);
        return a > b ? 1 : a < b ? -1 : 0;
        // return a.localeCompare(b);         // <-- alternative
      });
      if (this.statisticRange.length === 2) {
        this.reportDateFrom = this.statisticRange[0];
        this.reportDateTo = this.statisticRange[1];
        this.fetchItems();
      } else if (this.statisticRange.length === 1) {
        this.reportDateFrom = this.statisticRange[0];
        this.reportDateTo = this.statisticRange[0];
        this.fetchItems();
      } else {
        this.tasksStatistics = [];
      }
    },
    fetchItems: function () {
      this.isLoading = true;
      let { reportDateFrom, reportDateTo } = this;
      this.$store
        .dispatch(FETCH_PROXY_STAT, { reportDateFrom, reportDateTo })
        .then((payload) => {
          this.isLoading = false;
          this.proxyStatistics = payload.batch;
        });
    },
  },
  mounted() {
    this.reportDateFrom = moment().add(-1, "days").format("YYYY-MM-DD");
    this.reportDateTo = moment().format("YYYY-MM-DD");
    this.statisticRange = [this.reportDateFrom, this.reportDateTo];
    this.fetchItems();
  },
};
</script>

<style>
.v-tabs--vertical > .v-tabs-bar .v-tab {
  height: 30px !important;
}
</style>
