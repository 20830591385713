<template>
  <v-card elevation="2" :loading="isLoading">
    <v-system-bar flat color="blue lighten-4">
      <span class="text-subtitle-1">Состояние очередей</span>
      <v-spacer></v-spacer>
      <v-icon
        v-if="showContent"
        @click="showContent = !showContent"
        style="cursor: pointer"
        >mdi-chevron-down</v-icon
      >
      <v-icon v-else @click="showContent = !showContent" style="cursor: pointer"
        >mdi-chevron-up</v-icon
      >
    </v-system-bar>
    <div v-if="showContent" class="d-flex">
      <div style="max-width: 145px">
        <v-tabs
          vertical
          center-active
          v-model="selectedTab"
          color="grey darken-4"
          @change="fetchItems"
        >
          <v-tabs-slider color="blue lighten-4"></v-tabs-slider>
          <v-tab v-for="(target, i) in availableTargets" :key="i">
            {{ target }}
          </v-tab>
        </v-tabs>
      </div>
      <div class="flex-grow-1 ml-15">
        <v-select
          item-text="label"
          item-value="value"
          :items="periods"
          label="Период"
          v-model="selectedPeriod"
          style="max-width: 180px"
        ></v-select>
        <v-data-table
          :headers="headers"
          :items="taskQueueStatusList"
          class="elevation-0"
        >
          <template v-slot:item.queueType="{ item }">
            {{ type[item.queueType] }}
          </template>
          <template v-slot:item.total="{ item }">
            {{ item.counters.total }}
          </template>
          <template v-slot:item.pending="{ item }">
            {{ item.counters.pending }}
          </template>
          <template v-slot:item.sent="{ item }">
            {{ item.counters.sent }}
          </template>
        </v-data-table>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { FETCH_TASK_QUEUE_STATUS } from "@/store/modules/main/actions";
import moment from "moment";

export default {
  name: "TaskQueueStatus",
  data: function () {
    return {
      showContent: true,
      taskQueueStatusList: [],
      isLoading: false,
      selectedPeriod: 6,
      selectedTab: 0,
    };
  },
  computed: {
    ...mapGetters("service", ["scopes"]),
    availableTargets: function () {
      return this.scopes;
    },
    selectedTarget: function () {
      return this.availableTargets[this.selectedTab] || "facebook";
    },
    dateFrom: function () {
      return moment()
        .subtract(this.selectedPeriod, "hours")
        .format("YYYY-MM-DD HH:mm:ss");
    },
    periods: function () {
      return [
        { label: "за 6 часов", value: 6 },
        { label: "за 12 часов", value: 12 },
        { label: "за 24 часа", value: 24 },
        { label: "за 48 часов", value: 48 },
      ];
    },
    headers: function () {
      return [
        {
          text: "Тип очереди",
          align: "start",
          value: "queueType",
        },
        {
          text: "Всего задач за период",
          align: "start",
          value: "total",
        },
        {
          text: `Задачи в "Ожидании"`,
          align: "start",
          value: "pending",
        },
        {
          text: `Задачи в "Отправлено"`,
          align: "start",
          value: "sent",
        },
      ];
    },
    type: function () {
      return {
        parsing: "Парсинг задачи",
        atomic: "Атомарные задачи",
      };
    },
  },
  watch: {
    selectedPeriod: {
      handler: function () {
        this.fetchItems();
      },
      deep: true,
    },
  },
  methods: {
    fetchItems: function () {
      this.isLoading = true;
      this.taskQueueStatusList = [];
      const { dateFrom, target = this.selectedTarget } = this;
      this.$store
        .dispatch(FETCH_TASK_QUEUE_STATUS, { dateFrom, target })
        .then((payload) => {
          this.isLoading = false;
          this.taskQueueStatusList = payload.batch;
        });
    },
  },
  mounted() {
    this.fetchItems();
  },
};
</script>
