var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"2","loading":_vm.isLoading}},[_c('v-system-bar',{attrs:{"flat":"","color":"blue lighten-4"}},[_c('span',{staticClass:"text-subtitle-1"},[_vm._v("Статистика синхронизации")]),_c('v-spacer'),(_vm.showContent)?_c('v-icon',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.showContent = !_vm.showContent}}},[_vm._v("mdi-chevron-down")]):_c('v-icon',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.showContent = !_vm.showContent}}},[_vm._v("mdi-chevron-up")])],1),(_vm.showContent)?_c('div',{staticClass:"d-flex"},[_c('div',{staticStyle:{"max-width":"145px"}},[_c('v-tabs',{attrs:{"vertical":"","center-active":"","color":"grey darken-4"},model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},[_c('v-tabs-slider',{attrs:{"color":"blue lighten-4"}}),_vm._l((_vm.availableTargets),function(item){return _c('v-tab',{key:item},[_vm._v(" "+_vm._s(item)+" ")])})],2)],1),_c('div',{staticClass:"pa-3 ml-15",staticStyle:{"width":"calc(100% - 145px)"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"disable-pagination":"","disable-filtering":"","hide-default-footer":"","headers":_vm.scheduleHeaders,"items":_vm.scheduleByTarget,"sort-by":['lastSyncStatus'],"sort-desc":[false]},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"x-small":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.showTasks(item.id)}}},[_c('v-icon',[_vm._v("mdi-eye-outline")])],1)]}},{key:"item.regularDateMask",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFormattedSchedule(item.regularDateMask))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("dashboard.sync.action." + (item.action))))+" "),(item.parameters && item.parameters.privacy_settings)?_c('div',[_vm._v(" (С настройками приватности) ")]):_vm._e()]}},{key:"item.lastSyncStatus",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"ml-2"},'span',attrs,false),on),[(item.lastSyncStatus === 'error')?_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-alert-circle-outline")]):(item.lastSyncStatus === 'warning')?_c('v-icon',{attrs:{"color":"orange"}},[_vm._v("mdi-alert-outline")]):(
                    ['pending', 'sent', 'new'].indexOf(item.lastSyncStatus) >
                    -1
                  )?_c('v-icon',{attrs:{"color":"blue"}},[_vm._v("mdi-loading")]):_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-clock-check-outline")]),(
                    ['pending', 'sent', 'new'].indexOf(item.lastSyncStatus) >
                    -1
                  )?_c('span',[_vm._v("Обработка")]):_c('span',[_vm._v(_vm._s(item.lastSyncUpdatedItems)+"/"+_vm._s(item.lastSyncItems))])],1)]}}],null,true)},[_c('div',[_vm._v("Обновленно сущностей: "+_vm._s(item.lastSyncUpdatedItems))]),_c('div',[_vm._v("Общее количество сущностей: "+_vm._s(item.lastSyncItems))])])]}}],null,false,626715553)}),_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.showSchedule),callback:function ($$v) {_vm.showSchedule=$$v},expression:"showSchedule"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline primary lighten-3 white--text justify-space-between"},[_vm._v(" Список задач расписания "),_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","return-value":_vm.statisticRange,"offset-y":"","min-width":"100px"},on:{"update:returnValue":function($event){_vm.statisticRange=$event},"update:return-value":function($event){_vm.statisticRange=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticStyle:{"max-width":"300px"},attrs:{"solo":"","hide-details":"","name":"date-search","label":"Период","readonly":""},model:{value:(_vm.statisticRange),callback:function ($$v) {_vm.statisticRange=$$v},expression:"statisticRange"}},'v-text-field',attrs,false),on))]}}],null,false,2005125223),model:{value:(_vm.rangePickerModal),callback:function ($$v) {_vm.rangePickerModal=$$v},expression:"rangePickerModal"}},[_c('v-date-picker',{attrs:{"no-title":"","range":"","scrollable":""},model:{value:(_vm.statisticRange),callback:function ($$v) {_vm.statisticRange=$$v},expression:"statisticRange"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"grey"},on:{"click":function($event){_vm.rangePickerModal = false}}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.$refs.menu.save(_vm.statisticRange);
                    _vm.setRange();}}},[_vm._v(" "+_vm._s(_vm.$t("ok"))+" ")])],1)],1),_c('v-autocomplete',{staticStyle:{"max-width":"300px"},attrs:{"solo":"","items":_vm.availableStatuses,"item-text":"label","item-value":"key","label":"Статус","menu-props":"closeOnContentClick","deletable-chips":"","multiple":"","small-chips":"","hide-no-data":"","hide-selected":"","name":"status","hide-details":""},model:{value:(_vm.taskStatus),callback:function ($$v) {_vm.taskStatus=$$v},expression:"taskStatus"}}),_c('v-btn',{attrs:{"icon":"","color":"white","title":_vm.$t('close')},on:{"click":function($event){return _vm.closeSchedule()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.filteredData,"search":_vm.search},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("dashboard.sync.action." + (item.action))))+" ")]}},{key:"item.status",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("dashboard.sync.status." + (item.status))))+" ")]}}],null,false,3488428835)})],1)],1)],1)],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }