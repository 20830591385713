<template>
  <div class="about">
    <h1>{{ $t("dashboard.title") }}</h1>
    <div class="d-flex flex-wrap mt-5">
      <div style="width: 750px" class="mr-5 mb-5">
        <atomic-action-stat></atomic-action-stat>
      </div>
      <div style="width: 750px" class="mr-5 mb-5">
        <account-history-stat></account-history-stat>
      </div>
    </div>
    <div class="d-flex flex-wrap mt-5">
      <div style="width: 750px" class="mr-5 mb-5">
        <content-stat></content-stat>
      </div>
      <div style="width: 750px" class="mr-5 mb-5">
        <content-link-stat></content-link-stat>
      </div>
    </div>
    <div class="d-flex flex-wrap mt-5">
      <div style="width: 750px" class="mr-5 mb-5">
        <proxy-package-stat></proxy-package-stat>
      </div>
    </div>
    <div class="d-flex flex-wrap mt-5">
      <div style="width: 1520px" class="mr-5 mb-5">
        <proxy-stat></proxy-stat>
      </div>
    </div>
    <div class="d-flex flex-wrap mt-5">
      <div style="width: 1520px" class="mr-5 mb-5">
        <sync-tasks-stat></sync-tasks-stat>
      </div>
    </div>
    <div class="d-flex flex-wrap mt-5">
      <div style="width: 1520px" class="mr-5 mb-5">
        <task-queue-status></task-queue-status>
      </div>
    </div>
    <div class="d-flex flex-wrap mt-5">
      <div style="width: 750px" class="mr-5 mb-5">
        <emulation-summary></emulation-summary>
      </div>
    </div>
  </div>
</template>

<script>
import AccountHistoryStat from "./parts/AccountHistoryStat";
import AtomicActionStat from "./parts/AtomicActionStat";
import ProxyStat from "./parts/ProxyStat";
import ProxyPackageStat from "./parts/ProxyPackageStat";
import ContentStat from "./parts/ContentStat";
import ContentLinkStat from "./parts/ContentLinkStat";
import SyncTasksStat from "./parts/SyncTasksStat";
import { FETCH_SERVICES } from "../../store/actions/service";
import TaskQueueStatus from "@/views/main/parts/TaskQueueStatus";
import EmulationSummary from "@/views/main/parts/EmulationSummary";

export default {
  name: "Dashboard",
  components: {
    EmulationSummary,
    TaskQueueStatus,
    AccountHistoryStat,
    AtomicActionStat,
    ProxyStat,
    ProxyPackageStat,
    ContentStat,
    ContentLinkStat,
    SyncTasksStat,
  },
  methods: {
    loadServices: function () {
      this.dataLoading = true;
      this.$store
        .dispatch("service/" + FETCH_SERVICES, null, { root: true })
        .catch(() => {});
    },
  },
  mounted() {
    this.loadServices();
  },
};
</script>
