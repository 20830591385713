<template>
  <v-card elevation="2" :loading="isLoading">
    <v-system-bar flat color="blue lighten-4">
      <span class="text-subtitle-1"> Статистика статусов аккаунтов </span>
      <v-spacer></v-spacer>
      <v-icon
        v-if="showContent"
        @click="showContent = !showContent"
        style="cursor: pointer"
        >mdi-chevron-down</v-icon
      >
      <v-icon v-else @click="showContent = !showContent" style="cursor: pointer"
        >mdi-chevron-up</v-icon
      >
    </v-system-bar>
    <div v-if="showContent">
      <div class="d-flex">
        <div style="max-width: 145px">
          <v-tabs
            vertical
            center-active
            v-model="selectedTab"
            color="grey darken-4"
          >
            <v-tabs-slider color="blue lighten-4"></v-tabs-slider>
            <v-tab v-for="item in availableTargets" :key="item">
              {{ item }}
            </v-tab>
          </v-tabs>
        </div>
        <div class="pa-3" style="width: calc(100% - 145px)">
          <div class="d-flex flex-wrap pl-4">
            <v-list>
              <v-list-item>
                <v-list-item-action>
                  <v-switch
                    v-model="allStages"
                    color="blue lighten-3"
                  ></v-switch>
                </v-list-item-action>
                <v-list-item-title>Все стейджи</v-list-item-title>
              </v-list-item>
            </v-list>
            <v-checkbox
              v-for="(stage, i) in availableStages"
              v-model="selectedStages"
              :key="i"
              :label="stage.toString()"
              :value="stage"
              color="blue lighten-3"
              class="mr-6"
            ></v-checkbox>
          </div>

          <div class="px-6">
            <v-row>
              <v-col
                ><v-menu
                  ref="menu"
                  v-model="rangePickerModal"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  :return-value.sync="statisticRange"
                  offset-y
                  min-width="100px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      name="date-search"
                      label="Период"
                      v-model="statisticRange"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    no-title
                    range
                    scrollable
                    v-model="statisticRange"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="grey" @click="rangePickerModal = false">
                      {{ $t("cancel") }}
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="
                        $refs.menu.save(statisticRange);
                        setRange();
                      "
                    >
                      {{ $t("ok") }}
                    </v-btn>
                  </v-date-picker>
                </v-menu></v-col
              >
              <v-col>
                <v-autocomplete
                  :items="availableAccountTypeLabels"
                  item-text="title"
                  item-value="id"
                  menu-props="closeOnContentClick"
                  deletable-chips
                  multiple
                  small-chips
                  hide-no-data
                  hide-selected
                  name="type"
                  :label="attributeLabels.type"
                  v-model="selectedType"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </div>
          <line-chart :chartData="chartDataset" :options="options"></line-chart>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

import { FETCH_ACCOUNTS_HISTORY_STAT } from "@/store/modules/main/actions";

import LineChart from "@/views/base/charts/Line";
import moment from "moment";

import _ from "lodash";
import { FETCH_ACCOUNT_TYPES } from "@/store/actions/donor";

export default {
  name: "AccountHistoryStat",
  components: { LineChart },
  data: function () {
    return {
      showContent: true,
      accountsHistoryStatistics: [],
      selectedTab: 0,
      allStages: null,
      selectedStages: [3, 4],
      selectedType: [1],
      isLoading: true,
      menu: false,
      rangePickerModal: false,
      statisticRange: null,
      reportDateFrom: null,
      reportDateTo: null,
      availableAccountTypeLabels: [],
      attributeLabels: {
        type: this.$t("account.attribute.type"),
      },
    };
  },
  computed: {
    ...mapGetters("account/donor", ["availableStages"]),
    ...mapGetters("service", ["scopes"]),
    availableTargets: function () {
      return this.scopes;
    },
    selectedTarget: function () {
      return this.availableTargets[this.selectedTab];
    },
    availableDates: function () {
      return _.uniq(
        _.map(this.accountsHistoryStatistics, function (item) {
          return item.reportDate;
        })
      );
    },
    groupedItems: function () {
      return _.groupBy(this.accountsHistoryStatistics, "target");
    },
    itemsByTarget: function () {
      return _.get(this.groupedItems, this.selectedTarget, []);
    },
    itemsByStatus: function () {
      return this.itemsByTarget.length
        ? _.groupBy(this.itemsByTarget, "status")
        : {};
    },
    chartDataset: function () {
      const vm = this;
      const availableColors = [
        "#A5D6A7",
        "#ff9b64",
        "#ff1414",
        "#6eb5ff",
        "#c9c9c9",
        "#000000",
      ];
      let datasets = [];
      let groupIndex = 0;

      ["active", "blocked", "banned", "pending", "frozen", "archive"].forEach(
        function (status) {
          let group = _.get(vm.itemsByStatus, status, []);
          let data = [];
          vm.availableDates.forEach(function (date) {
            let items = _.filter(group, ["reportDate", date]);
            let value = 0;
            items.forEach(function (item) {
              if (
                item &&
                vm.selectedStages.indexOf(item.stage) > -1 &&
                vm.selectedType.indexOf(item.type) > -1
              ) {
                value += item.value;
              }
            });
            data.push(value);
          });

          datasets.push({
            label: status,
            fill: false,
            radius: 2,
            data: data,
            tension: 0.4,
            cubicInterpolationMode: "monotone",
            borderColor: availableColors[groupIndex],
            backgroundColor: availableColors[groupIndex],
          });
          groupIndex++;
        }
      );

      return {
        labels: vm.availableDates,
        datasets,
      };
    },
    options: function () {
      return {
        responsive: true,
        indexAxis: "y",
        plugins: {
          title: {
            text: "Статистика статусов аккаунтов",
            display: true,
          },
        },
        scales: {
          y: {
            min: 0,
          },
          xAxes: {
            type: "time",
            time: {
              // Luxon format string
              tooltipFormat: "DD T",
            },
            title: {
              display: true,
              text: "Date",
            },
          },
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
              title: {
                display: true,
                text: "value",
              },
            },
          ],
        },
        hover: {
          onHover: function (e) {
            var point = this.getElementAtEvent(e);
            if (point.length) e.target.style.cursor = "pointer";
            else e.target.style.cursor = "default";
          },
        },
        legend: {
          position: "bottom",
          labels: {
            padding: 5,
            usePointStyle: true,
            font: {
              size: 10,
              lineHeight: 1,
            },
          },
          onHover: function (e) {
            e.target.style.cursor = "pointer";
          },
        },
        animations: {
          tension: {
            duration: 0,
            easing: "linear",
            from: 1,
            to: 0,
            loop: false,
          },
        },
      };
    },
    formattedStages: function () {
      return this.selectedStages.join(", ");
    },
    formattedRange: function () {
      let { reportDateFrom, reportDateTo } = this;
      return [reportDateFrom, reportDateTo].join(" - ");
    },
  },
  watch: {
    allStages: function (newValue) {
      if (newValue) {
        this.selectedStages = [0, 2, 3, 4];
      } else {
        this.selectedStages = [];
      }
    },
  },
  methods: {
    setRange: function () {
      this.statisticRange.sort(function (a, b) {
        a = moment(a);
        b = moment(b);
        return a > b ? 1 : a < b ? -1 : 0;
        // return a.localeCompare(b);         // <-- alternative
      });
      if (this.statisticRange.length === 2) {
        this.reportDateFrom = this.statisticRange[0];
        this.reportDateTo = this.statisticRange[1];
        this.fetchItems();
      } else if (this.statisticRange.length === 1) {
        this.reportDateFrom = this.statisticRange[0];
        this.reportDateTo = this.statisticRange[0];
        this.fetchItems();
      } else {
        this.tasksStatistics = [];
      }
    },
    fetchItems: function () {
      this.isLoading = true;
      let { reportDateFrom, reportDateTo } = this;
      this.$store
        .dispatch(FETCH_ACCOUNTS_HISTORY_STAT, { reportDateFrom, reportDateTo })
        .then((payload) => {
          this.isLoading = false;
          this.accountsHistoryStatistics = payload.batch;
        });
    },
    fetchAccountTypes: function () {
      this.isLoading = true;
      this.$store
        .dispatch("account/donor/" + FETCH_ACCOUNT_TYPES, {
          limit: 50,
          offset: 0,
        })
        .then(
          (payload) => {
            this.isLoading = false;
            this.availableAccountTypeLabels = payload.data;
          },
          () => {}
        )
        .catch(() => {});
    },
  },
  mounted() {
    this.reportDateFrom = moment().add(-4, "days").format("YYYY-MM-DD");
    this.reportDateTo = moment().format("YYYY-MM-DD");
    this.statisticRange = [this.reportDateFrom, this.reportDateTo];
    this.fetchItems();
    this.fetchAccountTypes();
  },
};
</script>

<style>
.v-tabs--vertical > .v-tabs-bar .v-tab {
  height: 30px !important;
}
</style>
