var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"2","loading":_vm.isLoading}},[_c('v-system-bar',{attrs:{"flat":"","color":"blue lighten-4"}},[_c('span',{staticClass:"text-subtitle-1"},[_vm._v(" Статистика прокси ")]),_c('v-spacer'),(_vm.showContent)?_c('v-icon',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.showContent = !_vm.showContent}}},[_vm._v("mdi-chevron-down")]):_c('v-icon',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.showContent = !_vm.showContent}}},[_vm._v("mdi-chevron-up")])],1),(_vm.showContent)?_c('div',{staticClass:"d-flex px-4"},[_c('v-spacer'),_c('v-row',[_c('v-col',[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","return-value":_vm.statisticRange,"offset-y":"","min-width":"100px"},on:{"update:returnValue":function($event){_vm.statisticRange=$event},"update:return-value":function($event){_vm.statisticRange=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"name":"date-search","label":"Период","readonly":""},model:{value:(_vm.statisticRange),callback:function ($$v) {_vm.statisticRange=$$v},expression:"statisticRange"}},'v-text-field',attrs,false),on))]}}],null,false,2791395638),model:{value:(_vm.rangePickerModal),callback:function ($$v) {_vm.rangePickerModal=$$v},expression:"rangePickerModal"}},[_c('v-date-picker',{attrs:{"no-title":"","range":"","scrollable":""},model:{value:(_vm.statisticRange),callback:function ($$v) {_vm.statisticRange=$$v},expression:"statisticRange"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"grey"},on:{"click":function($event){_vm.rangePickerModal = false}}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.$refs.menu.save(_vm.statisticRange);
                _vm.setRange();}}},[_vm._v(" "+_vm._s(_vm.$t("ok"))+" ")])],1)],1)],1),_c('v-col',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Поиск","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1):_vm._e(),(_vm.showContent)?_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.filteredProxyStatistics,"sort-by":['actionsWithError'],"sort-desc":[true]},scopedSlots:_vm._u([{key:"item.stat",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-nowrap"},_vm._l((item.statistics),function(statisticItem,i){return _c('div',{key:i,staticClass:"px-3"},[_c('router-link',{staticClass:"text-decoration-none grey--text text--darken-4",attrs:{"to":{
              name: 'AccountList',
              query: {
                proxy: item.address,
                target: [statisticItem.name],
                selectedFilters: ['proxy', 'target'],
              },
            }}},[_c('v-icon',{domProps:{"textContent":_vm._s(
                'mdi-' +
                (statisticItem.name === 'vkontakte'
                  ? 'alpha-b-box'
                  : statisticItem.name)
              )}}),_c('span',{staticClass:"pl-1",domProps:{"textContent":_vm._s(statisticItem.value)}})],1)],1)}),0)]}},{key:"item.package",fn:function(ref){
              var item = ref.item;
return [(item.proxyPackage)?_c('router-link',{staticClass:"pr-1 d-inline-block text-truncate",staticStyle:{"max-width":"100%"},attrs:{"to":{
          name: 'ProxyPackageList',
          query: { id: item.proxyPackage.id },
        }}},[_vm._v(" "+_vm._s(item.proxyPackage.title)+" ")]):_vm._e(),(!item.proxyPackage)?_c('span',{staticClass:"d-lg-none grey--text caption"},[_vm._v(" Proxy package not set ")]):_vm._e()]}},{key:"item.actionsWithError",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.actionsWithError)+" / "+_vm._s(item.totalActions)+" ")])]}}],null,true)},[_c('div',[_vm._v("Действий с ошибкой: "+_vm._s(item.actionsWithError))]),_c('div',[_vm._v("Общее количество действий: "+_vm._s(item.totalActions))])])]}}],null,false,465053154)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }