<template>
  <v-card elevation="2" :loading="isLoading">
    <v-system-bar flat color="blue lighten-4">
      <span class="text-subtitle-1"
        >Статистика выдачи задач в рамках эмуляции</span
      >
      <v-spacer></v-spacer>
      <v-icon
        v-if="showContent"
        @click="showContent = !showContent"
        style="cursor: pointer"
        >mdi-chevron-down</v-icon
      >
      <v-icon v-else @click="showContent = !showContent" style="cursor: pointer"
        >mdi-chevron-up</v-icon
      >
    </v-system-bar>
    <div v-if="showContent" class="d-flex">
      <div style="max-width: 145px">
        <v-tabs
          vertical
          center-active
          v-model="selectedTab"
          color="grey darken-4"
          @change="fetchItems"
        >
          <v-tabs-slider color="blue lighten-4"></v-tabs-slider>
          <v-tab v-for="(target, i) in availableTargets" :key="i">
            {{ target }}
          </v-tab>
        </v-tabs>
      </div>
      <div class="flex-grow-1 ml-15">
        <v-select
          item-text="label"
          item-value="value"
          :items="periods"
          label="Период"
          v-model="selectedPeriod"
          style="max-width: 180px"
        ></v-select>
        <v-data-table
          :headers="headers"
          :items="emulationSummaryList"
          hide-default-header
          hide-default-footer
          class="elevation-0"
        >
          <template v-slot:item.id="{ item }">
            <v-btn x-small icon color="primary" @click="showDetails(item)">
              <v-icon>mdi-eye-outline</v-icon>
            </v-btn>
          </template>
          <template v-slot:item.count="{ item }">
            <span class="font-weight-bold black--text">{{ item.total }}</span
            >/
            <span class="font-weight-bold green--text">{{
              item.numberSuccessful
            }}</span
            >/
            <span class="font-weight-bold red--text">{{
              item.numberFailed
            }}</span>
          </template>
          <template v-slot:item.requestType="{ item }">
            {{ requestTypeAlias[item.requestType] }}
          </template>
        </v-data-table>
        <v-dialog
          v-model="showDetailsStatus"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-card-title
              class="headline primary lighten-3 white--text justify-space-between"
            >
              Детали эмуляции
              <v-btn
                icon
                color="white"
                :title="$t('close')"
                @click="showDetailsStatus = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>

            <v-card-text>
              <v-data-table
                :headers="detailsHeaders"
                :items="emulationDetailsList"
                class="elevation-0"
              >
                <template v-slot:item.type="{ item }">
                  {{ item.type }}
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import {
  FETCH_EMULATION_SUMMARY,
  FETCH_EMULATION_DETAILS,
} from "@/store/modules/main/actions";
import moment from "moment";

export default {
  name: "EmulationSummary",
  data: function () {
    return {
      showContent: true,
      emulationSummaryList: [],
      emulationDetailsList: [],
      isLoading: false,
      selectedPeriod: 6,
      selectedTab: 0,
      showDetailsStatus: false,
    };
  },
  computed: {
    ...mapGetters("service", ["scopes"]),
    availableTargets: function () {
      return this.scopes;
    },
    selectedTarget: function () {
      return this.availableTargets[this.selectedTab] || "facebook";
    },
    requestTypeAlias: function () {
      return {
        post: "Количество запросов контента на постинг",
        repost: "Количество запросов контента на репостинг",
        friending: "Количество запросов на френдинг",
        subscription: "Количество запросов на подписку",
      };
    },
    dateFrom: function () {
      return moment()
        .subtract(this.selectedPeriod, "hours")
        .format("YYYY-MM-DD HH:mm:ss");
    },
    periods: function () {
      return [
        { label: "за 6 часов", value: 6 },
        { label: "за 12 часов", value: 12 },
        { label: "за 24 часа", value: 24 },
        { label: "за 48 часов", value: 48 },
      ];
    },
    headers: function () {
      return [
        {
          text: "",
          align: "start",
          value: "requestType",
        },
        {
          text: "",
          align: "start",
          value: "count",
        },
        {
          text: "",
          align: "start",
          value: "id",
        },
      ];
    },
    detailsHeaders: function () {
      return [
        {
          text: "Дата запроса",
          align: "start",
          value: "requestDate",
        },
        {
          text: "ID аккаунта",
          align: "start",
          value: "userId",
        },
        {
          text: "Тип аккаунта",
          align: "start",
          value: "type",
        },
        {
          text: "Стейдж аккаунта",
          align: "start",
          value: "stage",
        },
        {
          text: "Тип запроса на контент",
          align: "start",
          value: "requestType",
        },
        {
          text: "Тип контента",
          align: "start",
          value: "contentType",
        },
        {
          text: "Тип ошибки",
          align: "start",
          value: "error",
        },
      ];
    },
    type: function () {
      return {
        parsing: "Парсинг задачи",
        atomic: "Атомарные задачи",
      };
    },
  },
  watch: {
    selectedPeriod: {
      handler: function () {
        this.fetchItems();
      },
      deep: true,
    },
  },
  methods: {
    fetchItems: function () {
      this.isLoading = true;
      this.emulationSummaryList = [];
      const { dateFrom, target = this.selectedTarget } = this;
      this.$store
        .dispatch(FETCH_EMULATION_SUMMARY, { dateFrom, target })
        .then((payload) => {
          this.isLoading = false;
          this.emulationSummaryList = payload.batch;
        });
    },
    showDetails: function ({ requestType }) {
      this.showDetailsStatus = true;
      this.emulationDetailsList = [];
      const { dateFrom, target = this.selectedTarget } = this;
      this.$store
        .dispatch(FETCH_EMULATION_DETAILS, {
          requestType: [requestType],
          target,
          dateFrom,
        })
        .then((payload) => {
          this.emulationDetailsList = payload.batch;
        });
    },
  },
  mounted() {
    this.fetchItems();
  },
};
</script>
