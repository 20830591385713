<template>
  <v-card elevation="2" :loading="isLoading">
    <v-system-bar flat color="blue lighten-4">
      <span class="text-subtitle-1">
        Статистика пакетов прокси
        <span v-if="reportDateFrom" class="ml-10"
          >{{ reportDateFrom }} - {{ reportDateTo }}</span
        >
      </span>
      <v-spacer></v-spacer>
      <v-icon
        v-if="showContent"
        @click="showContent = !showContent"
        style="cursor: pointer"
        >mdi-chevron-down</v-icon
      >
      <v-icon v-else @click="showContent = !showContent" style="cursor: pointer"
        >mdi-chevron-up</v-icon
      >
    </v-system-bar>
    <div v-if="showContent" class="d-flex px-4">
      <v-spacer></v-spacer>
      <v-row>
        <v-col>
          <v-menu
            ref="menu"
            v-model="rangePickerModal"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            :return-value.sync="statisticRange"
            offset-y
            min-width="100px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                name="date-search"
                label="Период"
                v-model="statisticRange"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker no-title range scrollable v-model="statisticRange">
              <v-spacer></v-spacer>
              <v-btn text color="grey" @click="rangePickerModal = false">
                {{ $t("cancel") }}
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  $refs.menu.save(statisticRange);
                  setRange();
                "
              >
                {{ $t("ok") }}
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Поиск"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
    </div>
    <v-data-table
      v-if="showContent"
      :headers="headers"
      :items="proxyStatistics"
      :sort-by="['proxyWithError']"
      :sort-desc="[true]"
      :search="search"
      class="elevation-0"
    >
      <template v-slot:item.title="{ item }">
        <router-link
          :to="{
            name: 'ProxyPackageList',
            query: { id: item.id },
          }"
          class="pr-1 d-inline-block text-truncate"
          style="max-width: 100%"
        >
          {{ item.title }}
        </router-link>
      </template>
      <template v-slot:item.source="{ item }">
        <router-link
          :to="{
            name: 'ProxyPackageList',
            query: { source: item.source },
          }"
          class="pr-1 d-inline-block text-truncate"
          style="max-width: 100%"
        >
          {{ item.source }}
        </router-link>
      </template>
      <template v-slot:item.proxyWithError="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on"
              >{{ item.proxyWithError }} / {{ item.proxiesAmount }}</span
            >
          </template>
          <div>Прокси с ошибкой: {{ item.proxyWithError }}</div>
          <div>Общее количество прокси: {{ item.proxiesAmount }}</div>
        </v-tooltip>
      </template>
      <template v-slot:item.accountWithError="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on"
              >{{ item.accountWithError }} / {{ item.accountAmount }}</span
            >
          </template>
          <div>Аккаунтов с ошибкой: {{ item.accountWithError }}</div>
          <div>Общее количество аккаунтов: {{ item.accountAmount }}</div>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { FETCH_PROXY_PACKAGE_STAT } from "@/store/modules/main/actions";
import moment from "moment";

export default {
  name: "ProxyPackageStat",
  data: function () {
    return {
      showContent: true,
      proxyStatistics: [],
      isLoading: true,
      menu: false,
      rangePickerModal: false,
      statisticRange: null,
      reportDateFrom: null,
      reportDateTo: null,
      search: null,
    };
  },
  computed: {
    formattedRange: function () {
      let { reportDateFrom, reportDateTo } = this;
      return [reportDateFrom, reportDateTo].join(" - ");
    },
    headers: function () {
      return [
        {
          text: "Пакет",
          align: "start",
          sortable: false,
          value: "title",
        },
        {
          text: "Поставщик",
          align: "start",
          sortable: false,
          value: "source",
        },
        {
          text: "Кол-во прокси с ошибкой",
          align: "start",
          value: "proxyWithError",
        },
        {
          text: "Aккаунтов с ошибкой",
          align: "start",
          value: "accountWithError",
        },
      ];
    },
  },
  methods: {
    setRange: function () {
      this.statisticRange.sort(function (a, b) {
        a = moment(a);
        b = moment(b);
        return a > b ? 1 : a < b ? -1 : 0;
        // return a.localeCompare(b);         // <-- alternative
      });
      if (this.statisticRange.length === 2) {
        this.reportDateFrom = this.statisticRange[0];
        this.reportDateTo = this.statisticRange[1];
        this.fetchItems();
      } else if (this.statisticRange.length === 1) {
        this.reportDateFrom = this.statisticRange[0];
        this.reportDateTo = this.statisticRange[0];
        this.fetchItems();
      } else {
        this.tasksStatistics = [];
      }
    },
    fetchItems: function () {
      this.isLoading = true;
      let { reportDateFrom, reportDateTo } = this;
      this.$store
        .dispatch(FETCH_PROXY_PACKAGE_STAT, { reportDateFrom, reportDateTo })
        .then((payload) => {
          this.isLoading = false;
          this.proxyStatistics = payload.batch;
        });
    },
  },
  mounted() {
    this.reportDateFrom = moment().add(-1, "days").format("YYYY-MM-DD");
    this.reportDateTo = moment().format("YYYY-MM-DD");
    this.statisticRange = [this.reportDateFrom, this.reportDateTo];
    this.fetchItems();
  },
};
</script>

<style>
.v-tabs--vertical > .v-tabs-bar .v-tab {
  height: 30px !important;
}
</style>
