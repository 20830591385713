<template>
  <v-card elevation="2" :loading="isLoading">
    <v-system-bar flat color="blue lighten-4">
      <span class="text-subtitle-1"
        >Статистика контентных ссылок и геометок</span
      >
      <v-spacer></v-spacer>
      <v-icon
        v-if="showContent"
        @click="showContent = !showContent"
        style="cursor: pointer"
        >mdi-chevron-down</v-icon
      >
      <v-icon v-else @click="showContent = !showContent" style="cursor: pointer"
        >mdi-chevron-up</v-icon
      >
    </v-system-bar>
    <div v-if="showContent" class="d-flex px-4">
      <v-row class="mt-3">
        <tag-search
          v-model="filters.tag"
          label="Проф.тип"
          :removeble="false"
          :allowEmpty="true"
        ></tag-search>
        <language-search
          v-model="filters.language"
          label="Язык"
          :removeble="false"
          :allowEmpty="true"
        ></language-search>
        <gender-search
          v-model="filters.gender"
          label="Пол"
          :removeble="false"
          :allowEmpty="true"
        ></gender-search>
        <age-group-search
          v-model="filters.ageGroup"
          label="Возраст"
          :removeble="false"
          :allowEmpty="true"
        ></age-group-search>
        <v-btn
          x-small
          outlined
          color="red"
          dark
          @click="resetFilters"
          class="mt-6"
        >
          {{ $t("resetFilters") }}
        </v-btn>
      </v-row>
    </div>
    <v-data-table
      v-if="showContent"
      :headers="headers"
      :items="filteredData"
      :search="search"
      class="elevation-0"
    >
      <template v-slot:item.gender="{ item }">
        <v-icon small>
          mdi-gender-{{ getSymbolByGender({ gender: item.gender }) }}
        </v-icon>
      </template>
      <template v-slot:item.language="{ item }">
        <flag :iso="getAbrByLanguage({ language: item.language })" />
      </template>
      <template v-slot:item.tag="{ item }">
        <v-chip v-if="item.tag" small>{{ item.tag.name }}</v-chip>
      </template>
    </v-data-table>
    <v-divider v-if="showContent"></v-divider>
    <div v-if="showContent" class="pa-4">
      Кол-во геометок: {{ markerCount }}
    </div>
  </v-card>
</template>

<script>
import {
  FETCH_CONTENT_LINK_STAT,
  FETCH_CONTENT_MARKER_STAT,
} from "@/store/modules/main/actions";
import GenderSearch from "../../search-parts/GenderSearch";
import { mapGetters } from "vuex";
import LanguageSearch from "../../search-parts/LanguageSearch";
import AgeGroupSearch from "../../search-parts/AgeGroupSearch";
import TagSearch from "../../search-parts/TagSearch";

export default {
  name: "ContentLinkStat",
  components: {
    LanguageSearch,
    GenderSearch,
    AgeGroupSearch,
    TagSearch,
  },
  data: function () {
    return {
      showContent: true,
      contentStatistics: [],
      isLoading: true,
      search: null,
      markerCount: null,
      filters: {
        gender: [],
        language: [],
        ageGroup: [],
        tag: [],
      },
    };
  },
  computed: {
    formattedRange: function () {
      let { reportDateFrom, reportDateTo } = this;
      return [reportDateFrom, reportDateTo].join(" - ");
    },
    headers: function () {
      return [
        {
          text: "Возраст",
          align: "start",
          value: "ageGroup",
        },
        {
          text: "Профтип",
          align: "start",
          sortable: false,
          value: "tag",
        },
        {
          text: "Пол",
          align: "start",
          value: "gender",
        },
        {
          text: "Язык",
          align: "start",
          value: "language",
        },
        {
          text: "Кол-во контента",
          align: "start",
          value: "qty",
        },
      ];
    },
    filteredData: function () {
      const vm = this;
      return this.contentStatistics.filter(function (item) {
        return (
          (vm.filters.gender.length === 0 ||
            (vm.filters.gender.indexOf("notSet") > -1 && !item.gender) ||
            vm.filters.gender.indexOf(item.gender) > -1) &&
          (vm.filters.language.length === 0 ||
            (vm.filters.language.indexOf("notSet") > -1 && !item.language) ||
            vm.filters.language.indexOf(item.language) > -1) &&
          (vm.filters.tag.length === 0 ||
            (vm.filters.tag.indexOf("notSet") > -1 && !item.tag) ||
            (item.tag && vm.filters.tag.indexOf(item.tag.id) > -1)) &&
          (vm.filters.ageGroup.length === 0 ||
            (vm.filters.ageGroup.indexOf("notSet") > -1 && !item.ageGroup) ||
            vm.filters.ageGroup.indexOf(item.ageGroup) > -1)
        );
      });
    },
    ...mapGetters("account/donor", ["getSymbolByGender", "getAbrByLanguage"]),
  },
  methods: {
    fetchItems: function () {
      this.isLoading = true;
      this.$store.dispatch(FETCH_CONTENT_MARKER_STAT).then((payload) => {
        this.markerCount = payload.qty;
      });
      this.$store.dispatch(FETCH_CONTENT_LINK_STAT).then((payload) => {
        this.isLoading = false;
        this.contentStatistics = payload.batch;
      });
    },
    resetFilters: function () {
      this.filters = {
        gender: [],
        language: [],
        ageGroup: [],
        tag: [],
      };
    },
  },
  mounted() {
    this.fetchItems();
  },
};
</script>

<style lang="scss" scoped>
@import "/src/theme/scss/resetGridPadding";
</style>
